import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    components: {
      default: () => import('../views/Login.vue'),
    },
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/reset',
    name: 'reset',
    components: {
      default: () => import('../views/PasswordReset.vue'),
    },
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/temporary-password',
    name: 'temporary-password',
    components: {
      default: () => import('../views/ResetTemporaryPassword.vue'),
    },
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/home',
    name: 'home',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/Home.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/events',
    name: 'events',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/Events.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/:id?',
    name: 'dashboard',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/Dashboard.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/users',
    name: 'users',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/Users.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/users-form/:id?',
    name: 'users-form',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/UserForm.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/testimonials',
    name: 'testimonials',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/Testimonials.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/features',
    name: 'features',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/Features.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/vouchers',
    name: 'vouchers',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/Vouchers.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/highlighted-suites',
    name: 'highlighted-suites',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/HighlightedSuites.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/property-management',
    name: 'property-management',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/PropertyManagement.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/users-management',
    name: 'users-management',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/UsersManagement.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/feature-categories',
    name: 'feature-categories',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/FeatureCategories.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/blocklist',
    name: 'blocklist',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/Blocklist.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/userlogs',
    name: 'userlogs',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/UserLogs.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/map-inventory',
    name: 'map-inventory',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/MapInventory.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/units',
    name: 'units',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/Units.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/contract/:id',
    name: 'contract',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/Contract.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/units-reports',
    name: 'units-reports',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/UnitsReport.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/payment-link',
    name: 'payment-link',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/PaymentLink.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/daily-prices',
    name: 'daily-prices',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/Pricing.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/companies',
    name: 'companies',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/Company.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/accounts-payable',
    name: 'accounts-payable',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/AccountsPayable.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/concierge',
    name: 'concierge',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/ConciergeUsers.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/precheckin/:queryParam?',
    name: 'precheckin',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/PreCheckIn.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/closure',
    name: 'closure',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/Closure.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/closure-parameters',
    name: 'closure-parameters',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/ClosureParameters.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/access-control',
    name: 'access-control',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/AccessControl.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/quality-dashboard',
    name: 'quality-dashboard',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/QualityDashboard.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/booking-rates',
    name: 'booking-rates',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/BookingRates.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/home',
  },
]

if (import.meta.env.VITE_NODE_ENV !== 'production') {
  routes.unshift({
    path: '/payment-simulation',
    name: 'payment-simulation',
    components: {
      header: () => import('../components/Header.vue'),
      default: () => import('../views/PaymentSimulation.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  })
}

export default routes
